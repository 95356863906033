import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
    static targets = [ 'id', 'destroy', 'container' ];

    remove = e => {
        const id = this.idTarget.value;
        if (id && id.length) {
            $(this.destroyTarget).val(1);
            $(this.containerTarget).addClass('d-none');
        }
        else $(this.containerTarget).remove();
    };
}