import { Controller } from "stimulus"
import $ from 'jquery';
import {initContentSearch, questionTypeToLabel} from "../utils";
import {CONTENT_TYPES, QUESTION_TYPES} from "../constants";

export default class extends Controller {
    static targets = [ 'prereqTemplate' ];
    $prereqsContainer = $('.prereqs-container');
    optionMap = {};
    questionTypeMap = {};

    connect() {
        initContentSearch($('select#prereqs-search'), this.onContentSelect, this.currContentIds);
        this.populateOptMap();
    };

    populateOptMap = () => {
        const contentIds = this.$prereqsContainer.find('.prereq input.required_content_id')
            .map((_, el) => $(el).val()).get();
        if (!contentIds.length) return;
        Promise.all(contentIds.map(cId => new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: `/contents/${cId}`,
                success: res => resolve(res.data),
                error: reject,
            })
        }))).then(contents => {
            contents.forEach(x => x.contentable?.exercise_questions?.forEach(({ id, question }) => {
                this.optionMap[id] = question.options;
                this.questionTypeMap[id] = question.question_type;
            }));
        });
    };

    onContentSelect = e => {
        const { id } = e.params.data;
        $.ajax({
            method: 'GET',
            url: `/contents/${id}`,
            success: res => this.addPrereq(res.data),
            error: console.error,
        })
    };

    addPrereq = content => {
        const { id, contentable, contentable_type } = content,
            { exercise_questions, name, internal_name } = contentable,
            uuid = new Date().valueOf();
        const $item = $(this.prereqTemplateTarget.innerHTML.replace(/TEMPLATE_CONTENT_PREREQ/g, uuid));
        $item.hide().appendTo(this.$prereqsContainer).fadeIn(300);
        $item.find('input.required_content_id').val(id);
        $item.find('h5.header').text(`${internal_name || name} - ${contentable_type}`);
        if (contentable_type === CONTENT_TYPES.EXERCISE && Array.isArray(exercise_questions) && exercise_questions.length) {
            $item.find('select.question_id').append(
                exercise_questions.map(({ id, question }) => {
                    const { internal_name, options, question_type } = question;
                    this.optionMap[id] = options;
                    this.questionTypeMap[id] = question_type;
                    return $('<option>', { value: id, text: `${internal_name} - ${questionTypeToLabel(question_type)}` });
                })
            );
            return;
        }
        $item.find('select.question_id').prop('disabled', true);
        $item.find('select.option_id').prop('disabled', true);
    };

    onExQuestionSelect = e => {
        e.preventDefault();
        const exQuesId = $(e.target).val();
        const uuid = $(e.target).data('uuid');
        const $option = $(`select.option_id[data-uuid="${uuid}"]`);
        $option.empty();
        $option.append($('<option>', { text: '', value: '' }));
        $option.append(
            (this.optionMap[exQuesId] || []).map(({ id, text }) => $('<option>', { text, value: id }))
        );
        $option.prop('disabled', !this.isOptionedType(this.questionTypeMap[exQuesId]));
    };

    isOptionedType = type => [
        QUESTION_TYPES.SINGLE_SELECT,
        QUESTION_TYPES.MULTIPLE_SELECT,
        QUESTION_TYPES.TRUE_FALSE,
    ].includes(type);

    currContentIds = () => this.$prereqsContainer.find('.prereq:not(.destroyed)').map((_, el) => {
        return $(el).find('input.required_content_id').val();
    }).get();

    remove = e => {
        e.preventDefault();
        const confirmed = confirm('Are you sure?');
        if (!confirmed) return;
        const $prereq = $(e.target).closest('.prereq'),
            id = $prereq.find('input.id').val();
        $prereq.fadeTo(300, 0.01, function() {
            $(this).slideUp(150, function() {
                if (id && id.length)  {
                    $prereq.find('input.destroy').val(1);
                    $prereq.removeClass('d-flex').addClass('d-none destroyed');
                }
                else $(this).remove();
            });
        });
    };
}