import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
    static targets = [  ];
    $optionsContainer;

    connect() {
        this.element[this.identifier] = this;
        this.$optionsContainer = $('.options-container');
    }

    moveUp = e => {
        e.preventDefault();
        const { index } = $(e.target).closest('.nested-fields').data(),
            optIndices = this.optionIndices(),
            position = optIndices.indexOf(index),
            newPosition = position - 1;
        if (newPosition < 0) return;
        const $container = $(e.target).closest('.list-group-item'),
            $neighbor = $(`.nested-fields[data-index="${optIndices[newPosition]}"]`).closest('.list-group-item');
        $container.animate({ height: 'toggle' }, 200).insertBefore($neighbor).animate({ height: 'toggle' }, 200);
        this.updateOrders();
    };

    moveDown = e => {
        e.preventDefault();
        const { index } = $(e.target).closest('.nested-fields').data(),
            optIndices = this.optionIndices(),
            position = optIndices.indexOf(index),
            newPosition = position + 1;
        if (newPosition >= this.numOptions()) return;
        const $container = $(e.target).closest('.list-group-item'),
            $neighbor = $(`.nested-fields[data-index="${optIndices[newPosition]}"]`).closest('.list-group-item');
        $container.animate({ height: 'toggle' }, 200).insertAfter($neighbor).animate({ height: 'toggle' }, 200);
        this.updateOrders();
    };

    optionIndices = () => this.$optionsContainer.find('.list-group-item:not(.d-none) .nested-fields').map(function() {
        return $(this).data('index');
    }).get();

    numOptions = () => this.$optionsContainer.find('.list-group-item').length;

    updateOrders = () => this.$optionsContainer.find('.list-group-item').each((idx, el) => {
        $(el).find('input.order').val(idx + 1);
    });
}