import * as $ from 'jquery';
import {
  initTableSearch,
  initTableLength,
} from "./utils";
import {DATATABLES_CONFIG} from "./constants";
import moment from "moment";

let table = null;

$(document).on('turbolinks:load', function() {
  if ($('table#membership-levels').length) {
    table = $('table#membership-levels').DataTable({
      columnDefs: [
        { targets: 5, orderable: false },
      ],
      order: [[ 4, 'desc' ]],
      ...DATATABLES_CONFIG(false),
    });

    initTableSearch(table);
    initTableLength(table);
  }

  const $membershipLevelForm = $('#membership_level_form');
  if ($membershipLevelForm.length) {
    $membershipLevelForm.submit(e => {
      const $form = $(e.target),
        $trialEnd = $form.find('#trial_end'),
        val = $trialEnd.datetimepicker('getValue');
      if (val) $trialEnd.val(moment(val).unix());
    });
  }
});

$(document).on('turbolinks:before-cache', function() {
  if (table) {
    table.destroy();
    table = null;
  }
});
