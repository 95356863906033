// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import {initIntInputs} from "./utils";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

import * as $ from "jquery";
import 'popper.js';
import 'bootstrap';
import 'datatables.net-bs4';
import * as Quill from 'quill';
import ImageResize from 'quill-image-resize';
Quill.register('modules/imageResize', ImageResize);
import 'jquery-mask-plugin';
import 'cropperjs';
import 'select2';
import 'jquery-datetimepicker';

import './home';
import './users';
import './content';
import './topic';
import './questions';
import './optionTemplates';
import './exercises';
import './datetime-moment';
import './assessments';
import './icons';
import './membershipLevels';

import '../../stylesheets/application.scss';

$(document).on('turbolinks:load', function() {
    $('.toast').toast('show');

    initIntInputs();

    $('input.datetime').each((idx, el) => {
      const $el = $(el);
      $el.datetimepicker($el.data() || {});
    })
});
