import $ from 'jquery';
import {
    initTableLength,
    initTableSearch,
} from "./utils";
import {DATATABLES_CONFIG} from "./constants";

let table = null;

$(document).on('turbolinks:load', function() {
    if ($('.assessments.index').length) {
        table = $('table#assessments').DataTable({
            order: [[ 3, 'desc' ]],
            ...DATATABLES_CONFIG(false),
            columnDefs: [
                { targets: 3, orderable: false },
            ],
        });

        initTableSearch(table);
        initTableLength(table);
    }
});
