import * as $ from 'jquery';
import {
    showSpinner,
    hideSpinner,
    initTableSearch,
    initTableLength,
    buildActionDropdown, initDeleteLinks
} from "./utils";
import moment from 'moment';
import {DATATABLES_CONFIG, MOMENT_FORMATS} from "./constants";
import {initStatusForms} from "./contentUtils";

let table = null;

$(document).on('turbolinks:load', function() {
    if ($('.exercises.index').length) {
        const $topic = $('select#topic'),
            $level = $('input#level');
        table = $('#exercises').DataTable({
            serverSide: true,
            searching: true,
            searchDelay: 500,
            processing: true,
            columns: [
                { data: 'internal_name' },
                { data: 'name' },
                { data: 'internal_notes', orderable: false },
                { data: 'prompt', orderable: false },
                { data: 'level' },
                {
                    data: 'topics',
                    orderable: false,
                    render: data => $('<span>').append(
                        $('<div>', { class: 'd-flex flex-column' }).append(data.map(name =>
                            $('<div>', { class: 'badge badge-secondary mb-1', text: name })
                        ))
                    ).html(),
                },
                {
                    data: 'status',
                    render: statusForm => $('<div>').append(statusForm).html(),
                },
                {
                    data: 'updated_at',
                    render: data => !!data ? moment(data).format(MOMENT_FORMATS.DATETIME) : '',
                },
                {
                    data: 'actions',
                    render: buildActionDropdown,
                    orderable: false,
                },
            ],
            ajax: {
                url: '/exercises',
                dataSrc: res => {
                    hideSpinner();
                    return res.data || [];
                },
                data: d => {
                    const params = {},
                        topic = $topic.val(),
                        level = $level.val();
                    if (topic && topic.length) params['topic'] = topic;
                    if (level && level.length) params['level'] = level;
                    return $.extend({}, d, params);
                },
                beforeSend: () => showSpinner(),
                error: () => hideSpinner(),
            },
            order: [[ 7, 'desc' ]],
            ...DATATABLES_CONFIG(false),
            drawCallback: () => {
                initDeleteLinks(table);
                initStatusForms();
            }
        });

        initTableSearch(table);
        initTableLength(table);

        $topic.change(() => table.draw());
        $level.change(() => table.draw());
    }
});

$(document).on('turbolinks:before-cache', function() {
    if (table) {
        table.destroy();
        table = null;
    }
});
