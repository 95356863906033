export const QUESTION_TYPES = {
    TEXT: 'text',
    AUDIO: 'audio',
    SINGLE_SELECT: 'single_select',
    MULTIPLE_SELECT: 'multiple_select',
    TRUE_FALSE: 'true_false',
};

export const MOMENT_FORMATS = {
    DATETIME: 'M/D/YY h:mm a',
    SERVER_FORMAT: 'YYYY-MM-DD HH:mm:ss Z',
}

export const CONTENT_TYPES = {
    EXERCISE: 'Exercise',
    STORY: 'Story',
    QUOTE: 'Quote',
}

export const DATATABLES_CONFIG = (filters = false) => ({
  dom: filters
    ? '<"table-wrapper"<"d-flex align-items-center" l<"ml-3" f>><t><"d-flex align-items-center justify-content-between" ip>>'
    : '<"table-wrapper"<t><"d-flex align-items-center justify-content-between" ip>>',
  pageLength: 100,
  pagingType: 'numbers',
})
