import * as $ from 'jquery';
import {
    initTableSearch,
    initTableLength,
    initDeleteLinks
} from "./utils";
import {DATATABLES_CONFIG} from "./constants";

let otTable = null;

$(document).on('turbolinks:load', function() {
    if ($('table#option-templates').length) {
        const $type = $('select#option-template-type');
        otTable = $('table#option-templates').DataTable({
            rowId: 0,
            columnDefs: [
                { targets: 0, visible: false },
                { targets: 1, visible: false },
                { targets: 4, orderable: false },
            ],
            order: [[ 3, 'desc' ]],
            ...DATATABLES_CONFIG(false),
            drawCallback: function() {
                initDeleteLinks(otTable);
            }
        });

        initTableSearch(otTable);
        initTableLength(otTable);

        $type.change(function() {
          if (!this.value || !this.value.length) otTable.columns(1).search('').draw();
          else otTable.columns(1).search(this.value).draw();
        })
    }
});

$(document).on('turbolinks:before-cache', function() {
    if (otTable) {
        otTable.destroy();
        otTable = null;
    }
});
