import { Controller } from "stimulus"
import $ from 'jquery';
import {initIntInputs} from "../utils";

export default class extends Controller {
    static targets = [ 'optionTemplate', 'optionsContainer', 'addOptionBtn' ];

    addOption = e => {
        e.preventDefault();
        const content = this.optionTemplateTarget.innerHTML.replace(/TEMPLATE_OPTION/g, new Date().valueOf());
        $(this.optionsContainerTarget).append(content);
        initIntInputs();
        // use options controller to update order input values
        this.optionsContainerTarget.options.updateOrders();
    };
}