import $ from 'jquery';
import {buildToast} from "./utils";

$(document).on('turbolinks:load', function() {
    const $form = $('form#topic');

    $('.modal#topic-modal').find('button.submit').click(function() {
        $form.find('input[type="submit"]').click();
    });
});

export const onRemoteTopicCreation = topic => {
    const { name, id } = topic || {};
    if (!name || !id) return;
    const $select = $('select#topic');
    $select.append($('<option>', { text: name, value: id }));
    $('.modal#topic-modal').modal('hide');
    buildToast('Success!', 'Topic created!');
};