import * as $ from 'jquery';
import {
    showSpinner,
    hideSpinner,
    initTableSearch,
    initTableLength,
    initDeleteLinks,
    buildActionDropdown,
    maybeShowTab,
} from "./utils";
import moment from 'moment';
import {DATATABLES_CONFIG, MOMENT_FORMATS} from "./constants";
import {
  initStoryForm,
  initTypeSelect,
  initImgEditor,
  initReadingForm,
  initAudioForm,
  imgEditorTeardown, initStatusForms, initExerciseForm
} from "./contentUtils";

let contentTable = null;
let topicsTable = null;

$(document).on('turbolinks:load', function() {
    if ($('.contents.new').length) {
        initTypeSelect(false);
    }

    if ($('.contents.index').length) {
        initContentTable();
        initTopicsTable();
        maybeShowTab();
    }

    if ($('.stories.edit').length || $('.stories.create').length || $('.stories.update').length) {
        initStoryForm();
    }

    if ($('.quotes.edit').length || $('.quotes.update').length) {
        const $form = $('form#quote');
        initImgEditor($form);
    }

    if ($('.audios.edit').length || $('.audios.update').length) {
        initAudioForm(false);
    }

    if ($('.readings.edit').length || $('.readings.update').length) {
        initReadingForm();
    }

    if ($('.exercises.edit').length || $('.exercises.update').length) {
        initExerciseForm();
    }
});

$(document).on('turbolinks:before-cache', function() {
    if (contentTable) {
        contentTable.destroy();
        contentTable = null;
    }
    if (topicsTable) {
        topicsTable.destroy();
        topicsTable = null;
    }
    imgEditorTeardown();
});

const initTopicsTable = () => {
  const $crisis = $('select#crisis'),
    $memLevel = $('select#membership_level'),
    $topicArchived = $('select#topic-archived'),
    $topicType = $('select#topic-type');

  topicsTable = $('table#topics').DataTable({
    order: [[ 6, 'desc' ]],
    serverSide: true,
    searching: true,
    searchDelay: 500,
    processing: true,
    ...DATATABLES_CONFIG(true),
    columns: [
      { data: 'name' },
      { data: 'internal_name' },
      {
        data: 'parent_topics',
        orderable: false,
        render: data => $('<span>').append(
          $('<div>', { class: 'd-flex flex-column' }).append(
            data.map(x => $('<span>', { class: 'badge badge-secondary mt-1', text: x }))
          )
        ).html()
      },
      {
        data: 'child_topics',
        orderable: false,
        render: data => $('<span>').append(
          $('<div>', { class: 'd-flex flex-column' }).append(
            data.map(x => $('<span>', { class: 'badge badge-secondary mt-1', text: x }))
          )
        ).html()
      },
      {
        data: 'crisis',
        render: data => {
          if (!data) return '';
          return '<i class="fas fa-check" />';
        },
      },
      {
        data: 'free',
        render: data => {
          if (!data) return '';
          return '<i class="fas fa-check" />';
        },
      },
      {
        data: 'membership_levels',
        orderable: false,
        render: data => $('<span>').append(
          $('<div>', { class: 'd-flex flex-column' }).append(
            data.map(x => $('<span>', { class: 'badge badge-secondary mt-1', text: x }))
          )
        ).html()
      },
      {
        data: 'updated',
        render: data => moment(data, MOMENT_FORMATS.SERVER_FORMAT).format(MOMENT_FORMATS.DATETIME)
      },
      {
        data: 'actions',
        render: buildActionDropdown,
        orderable: false,
      },
    ],
    ajax: {
      url: '/topics',
      dataSrc: res => {
        hideSpinner();
        return res.data || [];
      },
      data: d => {
        const p = {},
          archived = $topicArchived.val(),
          crisis = $crisis.val(),
          memLevel = $memLevel.val(),
          type = $topicType.val();
        if (archived && archived.length) p['archived'] = archived;
        if (crisis && crisis.length) p['crisis'] = crisis;
        if (memLevel && memLevel.length) p['membership_level'] = memLevel;
        if (type && type.length) p['topic_type'] = type;
        return $.extend({}, d, p);
      },
      beforeSend: () => showSpinner(),
      error: () => hideSpinner(),
    },
  });

  $crisis.change(() => topicsTable.draw());
  $memLevel.change(() => topicsTable.draw());
  $topicArchived.change(() => topicsTable.draw());
  $topicType.change(() => topicsTable.draw());
}

const initContentTable = () => {
  const $topic = $('select#topic'),
    $level = $('input#level'),
    $order = $('input#order'),
    $type = $('select#type'),
    $status = $('select#status'),
    $archived = $('select#archived'),
    $prereqs = $('select#prereqs');

  contentTable = $('#content').DataTable({
    serverSide: true,
    searching: true,
    searchDelay: 500,
    processing: true,
    ...DATATABLES_CONFIG(false),
    columns: [
      { data: 'internal_name' },
      { data: 'name' },
      { data: 'text', orderable: false },
      { data: 'internal_notes', orderable: false },
      { data: 'type' },
      { data: 'level' },
      {
        data: 'topics',
        orderable: false,
        render: data => $('<span>').append(
          $('<div>', { class: 'd-flex flex-column' }).append(data.map(name =>
            $('<div>', { class: 'badge badge-secondary mb-1', text: name })
          ))
        ).html(),
      },
      {
        data: 'status',
        render: statusForm => $('<div>').append(statusForm).html(),
      },
      {
        data: 'created',
        render: data => moment(data).format(MOMENT_FORMATS.DATETIME)
      },
      {
        data: 'updated',
        render: data => moment(data, MOMENT_FORMATS.SERVER_FORMAT).format(MOMENT_FORMATS.DATETIME)
      },
      {
        data: 'actions',
        render: buildActionDropdown,
        orderable: false,
      },
    ],
    ajax: {
      url: '/contents',
      dataSrc: res => {
        hideSpinner();
        return res.data || [];
      },
      data: d => {
        const p = {},
          topic = $topic.val(),
          level = $level.val(),
          order = $order.val(),
          type = $type.val(),
          status = $status.val(),
          archived = $archived.val(),
          prereqs = $prereqs.val();
        if (topic && topic.length) p['topic_id'] = topic;
        if (level && level.length) p['level'] = level;
        if (order && order.length) p['content_order'] = order;
        if (type && type.length) p['type'] = type;
        if (status && status.length) p['status'] = status;
        if (archived && archived.length) p['archived'] = archived;
        if (prereqs && prereqs.length) p['prereqs'] = prereqs;
        return $.extend({}, d, p);
      },
      beforeSend: () => showSpinner(),
      error: () => hideSpinner(),
    },
    order: [[ 9, 'desc' ]],
    drawCallback: () => {
      initStatusForms();
      initDeleteLinks(contentTable);
    }
  });

  $topic.change(() => contentTable.draw());
  $level.change(() => contentTable.draw());
  $order.change(() => contentTable.draw());
  $type.change(() => contentTable.draw());
  $status.change(() => contentTable.draw());
  $archived.change(() => contentTable.draw());
  $prereqs.change(() => contentTable.draw());

  initTableSearch(contentTable);
  initTableLength(contentTable);
}
