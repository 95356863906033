import { Controller } from "stimulus"
import $ from 'jquery';
import {initContentSearch, initIntInputs} from "../utils";

export default class extends Controller {
    static targets = [ 'scoreCategoryTemplate', 'addScoreCategoryBtn' ];
    $scoreCategoriesContainer = $('.score-categories-container');
    $contentsList = $('.contents-container');
    quesMap = {};

    connect() {
        initContentSearch($('select#contents'), this.onContentSelect, this.currContentIds, { type: 'Exercise' });
        this.initContentActions(this.$contentsList);
        const contentIds = this.currContentIds();
        Promise.all(contentIds.map(cId => new Promise((resolve, reject) =>
            $.ajax({
                method: 'GET',
                url: `/contents/${cId}`,
                success: res => resolve(res.data),
                error: reject,
            })
        ))).then(contents => {
            contents.forEach(content => {
                const { id, contentable } = content,
                    { exercise_questions } = contentable || {};
                if (exercise_questions && exercise_questions.length) this.quesMap[id] = exercise_questions;
            });
        });
        $('form#assessment').submit(function() {
          $('input:disabled').prop('disabled', false);
        });
    }

    addScoreCategory = e => {
        if (e) e.preventDefault();
        const content = this.scoreCategoryTemplateTarget.innerHTML.replace(/TEMPLATE_SCORE_CATEGORY/g, new Date().valueOf());
        this.$scoreCategoriesContainer.append(content);
        this.updateQuestionSelects();
        initIntInputs();
    };

    initContentActions = () => {
        $('button.trash:not(.bound)').addClass('bound').click(e => {
            e.preventDefault();
            const confirmed = confirm('Are you sure?')
            if (!confirmed) return;
            const $card = $(e.target).closest('.card.content'),
                id = $card.data('id');
            const { [id]: removed, ...rest } = this.quesMap;
            this.quesMap = rest;
            $('select#content-ids').find(`option[value="${id}"]`).remove();
            $card.fadeTo(300, 0.01, function() {
                $(this).slideUp(150, function() { $(this).remove() });
            });
            this.updateQuestionSelects();
        });
    }

    onContentSelect = e => {
        const { id, name, accordion_item, exercise_questions } = e.params.data,
            $item = $(accordion_item);
        $item.hide().appendTo(this.$contentsList).fadeIn(300);
        $('select#content-ids').append(
            $('<option>', { value: id, text: name }).prop('selected', true),
        );
        this.initContentActions();
        if (exercise_questions && exercise_questions.length)
            this.quesMap[id] = exercise_questions;
        this.updateQuestionSelects();
    };

    currContentIds = () => this.$contentsList.find('.card.content:not(.destroyed)').map(function() {
        return $(this).data('id');
    }).get();

    updateQuestionSelects = () => {
        const exQuestions = Object.values(this.quesMap).flat();
        $('select.exercise-question-ids').each((_, el) => {
            const $el = $(el);
            const currentIds = $el.val();
            $el.empty();
            $el.val('');
            $el.append(
              exQuestions.map(({ id, question }) =>
                    $('<option>', {
                        text: `${question.internal_name} - ${question.question_type}`,
                        value: id,
                        selected: currentIds.includes(id.toString())
                    })
              )
            );
        });
    }
}
