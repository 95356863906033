import Cropper from 'cropperjs';
import { debounce } from 'underscore';

class ImgEditor {
    OPTIONS = {
        preview: '.img-preview',
        viewMode: 0,
        crop: e => {
            const { height, width } = e.detail;
            this.$height.val(height.toFixed(2));
            this.$width.val(width.toFixed(2));
        },
        zoom: e => {
            this.zoomRatio = e.detail.ratio;
            this.$zoom.val(e.detail.ratio.toFixed(2));
        },
        ready: () => {
            this.cropper.zoomTo(1);
            const { scaleX, scaleY, naturalWidth, naturalHeight } = this.cropper.getImageData();
            if (!scaleX && !scaleY) this.$scale.val(1);
            this.reportImgDimensions();
        }
    };
    zoomRatio;
    $modal;
    $input;
    $imgContainer;
    $height;
    $width;
    $zoom;
    $scale;
    $imgH;
    $imgW;
    image;
    cropper;
    file;
    fileName;
    fileType;
    onSetFile;

    constructor($input, $modal, onSetFile = null) {
        this.onSetFile = onSetFile;
        this.$modal = $modal;
        this.$input = $input;
        this.$imgContainer = $modal.find('.img-container');
        this.$height = this.$modal.find('input#height');
        this.$width = this.$modal.find('input#width');
        this.$zoom = this.$modal.find('input#zoom');
        this.$scale = this.$modal.find('input#scale');
        this.$imgH = this.$modal.find('#img-h');
        this.$imgW = this.$modal.find('#img-w');
        this.onInputChange();
        this.onInput();
        this.onEditModalOpen();
        $('[data-toggle="tooltip"]').tooltip();
    }

    selectFullImg = () => {
        if (!this.cropper) return;
        const { scaleX, scaleY, naturalWidth, naturalHeight } = this.cropper.getImageData();
        this.cropper.setData({
            ...this.cropper.getData(),
            x: 0,
            y: 0,
            width: naturalWidth * (scaleX || 1),
            height: naturalHeight * (scaleY || 1),
        });
    }

    reportImgDimensions = () => {
        if (!this.cropper) return;
        const { naturalHeight, naturalWidth, scaleX, scaleY } = this.cropper.getImageData();
        this.$imgH.text(naturalHeight * (scaleY || 1));
        this.$imgW.text(naturalWidth * (scaleX || 1));
    }

    onEditModalOpen = () => this.$modal.on('shown.bs.modal', async e => {
        this.handleAction();
        const rowHeight = $(e.currentTarget).find('.container-fluid').height();
        this.$modal.find('.img-container').css('max-height', rowHeight).css('height', rowHeight);
        if (this.cropper) {
            this.cropper.destroy();
            this.$imgContainer.empty();
        }
        await this.initCropper();
    });

    initCropper = async () => {
        if (!this.file) return;
        const fileURL = await this.fileAsDataURL();
        this.image = new Image();
        this.image.onload = () => {
            this.$imgContainer.append(this.image);
            this.cropper = new Cropper(this.image, this.OPTIONS);
        };
        this.image.src = fileURL;
    }

    fileAsDataURL = async () => {
        if (!this.file) return;
        if (typeof this.file === 'string') return this.file;
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = () => {
                reader.abort();
                return reject('Error converting file to data url.');
            }
            reader.readAsDataURL(this.file);
        });
    }

    handleAction = () => this.$modal.find('.action-btn:not(.bound)').addClass('bound').click(e => {
        if (!this.cropper) return;
        const $btn = $(e.currentTarget);
        const { action, val } = $btn.data();
        switch (action) {
            case 'selectAll':
                this.selectFullImg();
                break;
            case 'cancel':
                this.$modal.modal('hide');
                break;
            case 'save':
                this.file = this.cropper.getCroppedCanvas().toDataURL('image/png');
                if (this.onSetFile) this.onSetFile(this.file);
                this.$modal.modal('hide');
                break;
            case 'setDragMode':
                const nextVal = val === 'crop' ? 'move' : 'crop';
                $btn.data('val', nextVal);
                this.cropper.setDragMode(nextVal);
                break;
            case 'rotate':
                this.cropper.rotate(val);
                break;
            case 'reset':
                this.cropper.reset();
                break;
            case 'aspectRatio':
                if (val === '') {
                    this.cropper.destroy();
                    this.cropper = new Cropper(this.image, this.OPTIONS);
                }
                else this.cropper.setAspectRatio(val);
                break;
            case 'zoomIn':
            case 'zoomOut':
                const containerData = this.cropper.getContainerData();
                this.cropper.zoomTo((this.zoomRatio || 0.5) + (action === 'zoomIn' ? 0.5 : -0.5), {
                    x: containerData.width / 2,
                    y: containerData.height / 2,
                });
                break;
        }
    })

    onInput = () => {
        this.$height.on('keyup change', debounce(e => {
            if (!this.cropper) return;
            const val = e.target.value;
            if (!val || !val.length) return;
            this.cropper.setCropBoxData({
                ...this.cropper.getCropBoxData(),
                height: parseFloat(val),
            });
        }, 300));

        this.$width.on('keyup change', debounce(e => {
            if (!this.cropper) return;
            const val = e.target.value;
            if (!val || !val.length) return;
            this.cropper.setCropBoxData({
                ...this.cropper.getCropBoxData(),
                width: parseFloat(val),
            });
        }, 300));

        this.$zoom.on('keyup change', debounce(e => {
            if (!this.cropper) return;
            const val = e.target.value;
            if (!val || !val.length) return;
            this.cropper.zoomTo(parseFloat(val));
        }, 300));

        this.$scale.on('keyup change', debounce(e => {
            if (!this.cropper) return;
            let val = e.target.value;
            if (!val || !val.length) return;
            val = parseFloat(val);
            this.cropper.scale(val, val);
            this.reportImgDimensions();
        }, 300));
    };

    onInputChange = () => this.$input.change(async e => {
        const { files } = e.target;
        if (!files || !files.length) return;
        this.file = files[0];
        this.fileName = this.file.name;
        this.fileType = this.file.type;
        if (this.onSetFile) {
            const fileURL = await this.fileAsDataURL();
            this.onSetFile(fileURL);
        }
    });

    fileAsBlob = async () => {
        if (!this.file) return;
        if (typeof this.file === 'string') {
            const blob = await fetch(this.file).then(res => res.blob());
            return blob;
        }
        return this.file;
    }

    setFile = (file, name, type) => {
        this.file = file;
        this.fileName = name;
        this.fileType = type;
        if (this.onSetFile) this.onSetFile(file);
    }

    teardown = () => {
        if (this.cropper) this.cropper.destroy();
        this.$imgContainer.empty();
    }
}

export default ImgEditor;