import * as $ from "jquery";
import {buildToast, cacheBustImg, hideSpinner, initQuill, showSpinner} from "./utils";
import ImgEditor from "./ImgEditor";

let imgEditors = {};
let editor = null;

export const initTypeSelect = (modal = false) => $('select#content-type').change(function() {
    if (!this.value || !this.value.length) return;
    const $link = $(`a[data-type="${this.value}"]`);
    $link.off('ajax:success')
        .on('ajax:success', () => {
            if (this.value === 'story') initStoryForm();
            else if (this.value === 'quote') initImgEditor($('#content-form-container').find('form'));
            else if (this.value === 'audio') initAudioForm(modal);
            else if (this.value === 'reading') initReadingForm();
            if (modal) {
                $('#content-form-container').find('input[type="submit"]').addClass('d-none');
                $('a.btn.back').addClass('d-none');
            }
        });
    if ($link.length) $link[0].click();
});

export const initReadingForm = () => {
    const $form = $('form#reading');
    const $textarea = $('textarea#text');
    initQuill(editor, $textarea, 'Compose reading text...');
    initImgEditor($form);
};

export const initAudioForm = (modal = false) => {
    const $textarea = $('textarea#prompt');
    initQuill(editor, $textarea, 'Compose prompt...');
    if (!modal) return;
    const $form = $('form#audio');
    $form.submit(function(e) {
        e.preventDefault();
        showSpinner();
        $form.find('input[type="submit"]').prop('disabled', true);
        const action = $(this).attr('action'),
            formId = $(this).attr('id');
        const data = new FormData(this);
        const request = new XMLHttpRequest();
        request.open('POST', action);
        request.setRequestHeader('Accept', 'application/json')
        request.send(data);
        request.onload = () => {
            hideSpinner();
            try {
                const res = JSON.parse(request.response);
                if (request.status === 200) {
                    const { data } = res || {},
                        { content } = data || {};
                    $('#content-form-container').trigger('wecon:content-created', [ content ]);
                }
                else {
                    const { errors } = res;
                    let msg = 'An error occurred!';
                    if (Array.isArray(errors) && errors.length)
                        msg = errors.join('. ');
                    buildToast('Oops...', msg, undefined, undefined, 'error');
                }
            } catch (e) {
                console.error(e);
            }
        }
    });
};

export const initStoryForm = () => {
    const $form = $('form#story');
    const $textarea = $('textarea#text');
    initQuill(editor, $textarea, 'Compose story text...');
    initImgEditor($form);
};

export const initExerciseForm = () => {
    const $form = $('form#exercise-form');
    initImgEditor($form);
};

export const initImgEditor = $form => {
    $form.find('input.editable-img').each(function(idx, el) {
        const $input = $(el),
            { uuid, url: fileURL, filename, filetype } = $input.data(),
            $modal = $(`#img-editor-modal[data-uuid="${uuid}"]`),
            $container = $(`.editable-img-container[data-uuid="${uuid}"]`),
            $thumbnailLink = $('a.img-thumb'),
            $thumbnail = $container.find('.img-thumbnail'),
            $editLink = $container.find('#edit-img-link');

        $thumbnailLink.click(function(e) {
          const href = $(e.currentTarget).attr('href');
          if (href === '#') {
            e.preventDefault();
            $input.click();
          }
        });

        imgEditors[uuid] = new ImgEditor($input, $modal, fileURL => {
            $thumbnail.attr('src', fileURL);
            $editLink.removeClass('d-none');
        });

        if (fileURL) imgEditors[uuid].setFile(cacheBustImg(fileURL), filename, filetype);

        $editLink.click(() => $modal.modal('show'));
    });
    onEditableImgFormSubmit($form);
};

/**
 * Handle story/quote form submission. Prevent default, add modified image file to form,
 * then manually submit via ajax
 * @returns {*|jQuery}
 */
const onEditableImgFormSubmit = $form => $form.submit(function(e) {
    e.preventDefault();
    showSpinner();
    $form.find('input[type="submit"]').prop('disabled', true);
    const action = $(this).attr('action'),
        formId = $(this).attr('id'),
        uuid = $(e.target).data('uuid'),
        editor = imgEditors[uuid];
    const data = new FormData(this);
    editor.fileAsBlob().then(blob => {
        if (blob) {
            const file = new File([ blob ], editor.fileName, { type: editor.fileType });
            data.set(imgInputName($(this)), file);
        }
        const request = new XMLHttpRequest();
        request.open('POST', action);
        request.setRequestHeader('Accept', 'application/json')
        request.send(data);
        request.onload = () => {
            hideSpinner();
            try {
                const res = JSON.parse(request.response);
                $form.find('input[type="submit"]').prop('disabled', false);
                if (request.status === 200) {
                    const { data } = res || {},
                        { redirect, content } = data || {};
                    // User is creating Content from Topic form using modal
                    if (window.location.href.includes('/topics') && !action.includes('/topics'))
                        $('#content-form-container').trigger('wecon:content-created', [ content ]);
                    else if (redirect)
                        window.location.href = redirect;
                }
                else {
                    const { errors } = res;
                    let msg = 'An error occurred!';
                    if (Array.isArray(errors) && errors.length)
                        msg = errors.join('. ');
                    buildToast('Oops...', msg, undefined, false, 'error');
                }
            } catch (e) {
                console.error(e);
            }
        }
    });
    return false;
});

const imgInputName = $form => {
    const id = $form.attr('id');
    if (id === 'topic') return 'topic[img]';
    else if (id === 'story') return 'story[img]';
    else if (id === 'quote') return 'quote[img]';
};

export const imgEditorTeardown = () => {
    Object.values(imgEditors).forEach(x => x.teardown());
    imgEditors = {};
}

export const initStatusForms = () => $('select.status:not(.bound)').addClass('bound').change(function() {
    const $form = $(this).closest('form');
    $form.off('ajax:success')
        .on('ajax:success', () => buildToast('Success!', 'Status updated!'))
        .off('ajax:error')
        .on('ajax:error', () => buildToast('Ooops...', 'An error occurred!', undefined, undefined, 'error'));
    $form.find('input[type="submit"]').click();
});
