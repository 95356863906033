import $ from 'jquery';
import moment from "moment";
import {DATATABLES_CONFIG, MOMENT_FORMATS} from "./constants";

let iconsTable = null;

$(document).on('turbolinks:load', function() {
    if ($('table#icons').length) {
        iconsTable = $('table#icons').DataTable({
            ...DATATABLES_CONFIG(true),
            order: [[ 2, 'desc' ]],
            columnDefs: [
                { targets: 3, orderable: false },
                {
                    targets: 2,
                    render: data => moment(data, MOMENT_FORMATS.SERVER_FORMAT).format(MOMENT_FORMATS.DATETIME)
                },
            ],
        });
    }
});

$(document).on('turbolinks:before-cache', function() {
    if (iconsTable) {
        iconsTable.destroy();
        iconsTable = null;
    }
});
