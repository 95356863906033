import * as $ from 'jquery';
import {
    showSpinner,
    hideSpinner,
    initTableSearch,
    initTableLength,
    buildActionDropdown, initDeleteLinks, maybeShowTab
} from "./utils";
import moment from 'moment';
import {DATATABLES_CONFIG, MOMENT_FORMATS} from "./constants";

let table = null;

$(document).on('turbolinks:load', function() {
    if ($('.questions.index').length) {
        maybeShowTab();
        const $type = $('select#type');
        table = $('#questions').DataTable({
            serverSide: true,
            searching: true,
            searchDelay: 500,
            processing: true,
            columns: [
                { data: 'internal_name' },
                { data: 'question_type' },
                { data: 'prompt' },
                {
                    data: 'required',
                    render: required => required ? '<i class="fas fa-check"></i>' : '',
                },
                {
                    data: 'custom_responses',
                    render: required => required ? '<i class="fas fa-check"></i>' : '',
                },
                {
                    data: 'updated_at',
                    render: data => !!data ? moment(data).format(MOMENT_FORMATS.DATETIME) : '',
                },
                {
                    data: 'actions',
                    render: buildActionDropdown,
                    orderable: false,
                },
            ],
            ajax: {
                url: '/questions',
                dataSrc: res => {
                    hideSpinner();
                    return res.data || [];
                },
                data: d => {
                    const params = {},
                        type = $type.val();
                    if (type && type.length) params['type'] = type;
                    return $.extend({}, d, params);
                },
                beforeSend: () => showSpinner(),
                error: () => hideSpinner(),
            },
            order: [[ 5, 'desc' ]],
            ...DATATABLES_CONFIG(false),
            drawCallback: () => {
                initDeleteLinks(table);
            }
        });

        initTableSearch(table);
        initTableLength(table);

        $type.change(() => table.draw());

        initTabs();
    }
});

$(document).on('turbolinks:before-cache', function() {
    if (table) {
        table.destroy();
        table = null;
    }
});

const initTabs = () => {
  const toggleFilters = type => {
    $('.filters').addClass('d-none');
    $(`.filters.${type}-filters`).removeClass('d-none');
  }
  const $tabs = $('#question-tabs');
  $tabs.on('show.bs.tab', e => {
    const { type } = $(e.target).data();
    toggleFilters(type);
  });
  const { type } = $tabs.find('.nav-link.active').data();
  toggleFilters(type);
}
