import { debounce } from 'underscore';
import * as $ from "jquery";
import * as Quill from "quill";
import * as moment from "moment";

export function showSpinner() {
    const $spinner = $('#preloader-overlay');
    if ($spinner) $spinner.show();
}

export function hideSpinner() {
    const $spinner = $('#preloader-overlay');
    if ($spinner) $spinner.hide();
}

export const initIntInputs = () => $('input.int:not(.bound)')
    .addClass('bound').mask('Y', {
        clearIfNotMatch: true,
        translation: {
            Y: { pattern: /[0-9]/, recursive: true }
        },
    });

export const buildToast = (header, body, delay = 5000, autohide = true, type = 'success') => {
    const $toast = $('<div>', { class: 'toast', id: 'action-toast', role: 'alert' }).append([
        $('<div>', { class: `toast-header text-white ${type === 'success' ? 'bg-success' : 'bg-danger'}` }).append([
            $('<i>', { class: `fas mr-2 ${type === 'success' ? 'fa-check-circle' : 'fa-exclamation-triangle'}` }),
            $('<strong>', { text: header, class: 'mr-auto' }),
            $('<button>', { class: 'close ml-3 text-white', type: 'button' }).attr('data-dismiss', 'toast').append(
                $('<i>', { class: 'fas fa-times fa-xs' })
            )
        ]),
        $('<div>', { class: 'toast-body' }).append(body),
    ]);
    $('.main').append($toast);
    $toast.toast({
        delay,
        autohide,
    }).on('hidden.bs.toast', function() {
        $toast.remove();
    });
    $toast.toast('show');
};

export function initTableSearch(table, debounceMs = 300) {
    const searchInput = $('input#table-search');
    if (!searchInput.length) return;
    searchInput.on('keyup change', debounce(function(e) {
        // prevents blur event from triggering change
        if (e.type === 'change' && e.target.nodeName === 'INPUT') return false;
        table.search( this.value ).draw();
    }, debounceMs));
}

export function initTableLength(table) {
    const lenSelect = $('select#table-length');
    if (!lenSelect.length) return;
    // select the appropriate option, based on datatable 'pageLength' config option
    lenSelect.find(`option[value="${table.page.len()}"]`).prop('selected', true);
    lenSelect.on('change', function() {
        if (!table) return;
        if (table.context.length) table.context["0"]._iDisplayLength = this.value;
        table.draw();
    });
}

export const csrfToken = () => $('meta[name="csrf-token"]').attr('content');

export const fetchPostHeaders = () => ({
    'X-CSRF-Token': csrfToken(),
    'Content-Type': 'application/json',
});

export const buildActionDropdown = actions => {
    const $menu = $('<div>', { class: 'dropdown-menu' }).append(
        actions.map(({ url, label, icon, method, remote, confirm }) =>{
            const $a = $('<a>', { class: 'dropdown-item', href: url }).append([
                $('<i>', { class: `fa-fw ${icon}` }),
                $('<span>', { text: label, class: 'ml-2' })
            ]).attr('data-turbolinks', false);
            if (method === 'delete') $a.addClass('delete').attr('data-method', method);
            else if (method) $a.attr('data-method', method);
            if (confirm && confirm.length) $a.attr('data-confirm', confirm);
            if (remote) $a.attr('data-remote', true);
            return $a;
        })
    );
    const $btn = $('<button>', { class: 'btn btn-secondary btn-sm dropdown-toggle', text: 'Actions' })
        .attr('type', 'button')
        .attr('data-toggle', 'dropdown');
    return $('<span>').append(
        $('<div>', { class: 'dropdown' }).append([
            $btn,
            $menu,
        ])
    ).html();
};

export const questionTypeToLabel = type => {
    switch (type) {
        case 'text':
            return 'Text';
        case 'single_select':
            return 'Single Select';
        case 'multiple_select':
            return 'Multiple Select';
        case 'true_false':
            return 'True / False';
        case 'audio':
            return 'Audio';
    }
}

export const initQuill = (editor, $textarea, placeholder) => {
    editor = new Quill('#text-editor', {
        modules: {
            imageResize: {
                displaySize: true
            },
            toolbar: {
                handlers: {
                    link: function(val) {
                        if (val) {
                            let href = prompt('Enter the URL');
                            this.quill.format('link', href);
                        } else {
                            this.quill.format('link', false);
                        }
                    }
                },
                container: [
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'indent': '-1'}, { 'indent': '+1' }],
                    ['image', 'link'],
                    [{ 'font': [] }],
                    [{ 'align': [] }],
                    ['clean'],
                ],
            }
        },
        placeholder,
        theme: 'snow',
        bounds: '#editor-wrapper',
    });

    if ($textarea.val()) {
        const delta = editor.clipboard.convert($textarea.val());
        editor.setContents(delta, 'silent');
    }

    // update hidden content input with editor content on change
    editor.on('text-change', debounce(() => {
        $textarea.val(editor.root.innerHTML);
    }, 300));
};

export const initContentSearch = ($select, onSelect, excludeContentIds = null, params = {}) => {
    $select.select2({
        allowClear: true,
        placeholder: 'Search content...',
        theme: "bootstrap",
        templateResult: ({ contentable_type, name, internal_name, stripped_text, stripped_prompt }) => {
            const $ul = $('<ul>', { class: 'list-unstyled' }).append([
                $('<li>', { class: 'd-flex' }).append([
                    $('<strong>', { text: 'Type', class: 'mr-3' }),
                    $('<div>', { class: 'ml-auto text-right', text: contentable_type }),
                ]),
                $('<li>', { class: 'd-flex' }).append([
                    $('<strong>', { text: 'Name', class: 'mr-3' }),
                    $('<div>', { class: 'ml-auto text-right', text: name }),
                ]),
            ]);
            if (internal_name) $ul.append(
                $('<li>', { class: 'd-flex' }).append([
                    $('<strong>', { text: 'Internal Name', class: 'mr-3' }),
                    $('<div>', { class: 'ml-auto text-right', text: internal_name }),
                ]),
            );
            if (stripped_text && stripped_text.length) $ul.append(
                $('<li>', { class: 'd-flex' }).append([
                    $('<strong>', { text: 'Text', class: 'mr-3' }),
                    $('<div>', { class: 'ml-auto text-right', text: stripped_text }),
                ]),
            );
            if (stripped_prompt && stripped_prompt.length) $ul.append(
                $('<li>', { class: 'd-flex' }).append([
                    $('<strong>', { text: 'Prompt', class: 'mr-3' }),
                    $('<div>', { class: 'ml-auto text-right', text: stripped_prompt }),
                ]),
            );
            return $('<span>').append($ul);
        },
        ajax: {
            delay: 250,
            url: '/search_contents',
            dataType: 'json',
            data: p => {
                const { term } = p;
                return {
                    q: term,
                    exclude: excludeContentIds ? excludeContentIds() : null,
                    ...params,
                };
            },
            processResults: data => {
                const opts = data.map(x => {
                    const { id, contentable, contentable_type, accordion_item } = x,
                        { name, internal_name, text, stripped_text, stripped_prompt, questions, exercise_questions } = contentable || {};
                    return {
                        id,
                        contentable_type,
                        name,
                        internal_name,
                        text,
                        stripped_text,
                        accordion_item,
                        stripped_prompt,
                        questions,
                        exercise_questions,
                    }
                });
                return { results: opts };
            }
        }
    }).on('select2:select', onSelect);
    $select.on('select2:close', () => {
        $select.val(null).trigger('change.select2');
    });
}

export const initDeleteLinks = table => $('a.delete:not(.bound)').addClass('bound')
    .off('ajax:success')
    .on('ajax:success', () => {
        buildToast('Success!', 'Item deleted!');
        if (table) table.draw();
    })
    .off('ajax:error')
    .on('ajax:error', (e) => {
        const [ data ] = e.detail,
            { errors } = data || {};
        let err = 'Unable to delete item!';
        if (Array.isArray(errors) && errors.length) err = errors[0];
        buildToast('Oops...', err, undefined, false, 'error');
    });

export const maybeShowTab = () => {
    const queryString = window.location.search,
        urlParams = new URLSearchParams(queryString),
        tab = urlParams.get('tab');
    if (tab && tab.length) $(`.nav-link[href="#${tab}-tab"]`).tab('show');
};

export const cacheBustImg = url => {
  if (!url || !url.length) return url;
  if (url.includes('data:')) return url;
  const [ part ] = url.split('?');
  return `${part}?ts=${moment().unix()}`;
}
