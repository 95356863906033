import * as $ from 'jquery';
import moment from "moment";
import {
  buildActionDropdown,
  hideSpinner,
  initDeleteLinks,
  initTableLength,
  initTableSearch,
  showSpinner
} from "./utils";
import {DATATABLES_CONFIG, MOMENT_FORMATS} from "./constants";
import {initStatusForms} from "./contentUtils";

let table = null;

$(document).on('turbolinks:load', function() {
    const $role = $('select#role'),
      $memLevel = $('select#membership_level');
    table = $('#users').DataTable({
        order: [[ 3, 'desc' ]],
        serverSide: true,
        searching: true,
        searchDelay: 500,
        processing: true,
        ...DATATABLES_CONFIG(false),
        columns: [
            { data: 'email' },
            { data: 'role' },
            { data: 'membership_level' },
            { data: 'created', render: data => data ? moment(data).format(MOMENT_FORMATS.DATETIME) : '' },
            {
                data: 'actions',
                render: buildActionDropdown,
                orderable: false,
            },
        ],
        ajax: {
            url: '/users',
            dataSrc: res => {
                hideSpinner();
                return res.data || [];
            },
            data: d => {
                const params = {},
                    role = $role.val(),
                    memLevel = $memLevel.val();
                if (role && role.length) params['user_role_id'] = role;
                if (memLevel && memLevel.length) params['membership_level'] = memLevel;
                return $.extend({}, d, params);
            },
            beforeSend: () => showSpinner(),
            error: () => hideSpinner(),
        },
        drawCallback: () => {
          initDeleteLinks(table);
        }
    });

    initTableSearch(table);
    initTableLength(table);

    $role.change(() => table.draw());
    $memLevel.change(() => table.draw());
});

$(document).on('turbolinks:before-cache', function() {
    if (table) {
        table.destroy();
        table = null;
    }
});
